<template>
  <div class="personal-center-container">
    <el-card shadow="hover">
      <el-form ref="form" label-width="80px" :model="form" :rules="rules">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="联系方式" name="link_tel">
            <el-col :lg="12" :md="16" :sm="24" :xl="12" :xs="24">
              <el-form-item label="客服电话">
                <el-input v-model="form.link_tel" :disabled="!isEdit" />
              </el-form-item>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="常见问题" name="faq">
            <el-form-item class="vab-quill-content" label="内容" prop="faq">
              <div class="news-content" v-if="isEdit">
                <vab-quill
                  ref="vab-quill"
                  v-model="form.faq"
                  :min-height="400"
                  :options="options"
                />
              </div>
              <div v-else v-html="form.faq" class="news-content"></div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="用户协议" name="agreement">
            <el-form-item class="vab-quill-content" label="内容" prop="agreement">
              <div class="news-content" v-if="isEdit">
                <vab-quill
                  ref="vab-quill"
                  v-model="form.agreement"
                  :min-height="400"
                  :options="options"
                />
              </div>
              <div v-else v-html="form.agreement" class="news-content"></div>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="隐私协议" name="privacy">
            <el-form-item class="vab-quill-content" label="内容" prop="privacy">
              <div class="news-content" v-if="isEdit">
                <vab-quill
                  ref="vab-quill"
                  v-model="form.privacy"
                  :min-height="400"
                  :options="options"
                />
              </div>
              <div v-else v-html="form.privacy" class="news-content"></div>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <el-form-item>
          <el-button type="primary" @click="onEdit">{{
            !isEdit ? "编辑" : "取消"
          }}</el-button>
          <el-button type="primary" @click="onSubmit" v-if="isEdit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { querySmsDataList } from "@/project/eztree/baseApi/commonApi";
import VabQuill from "@/extra/VabQuill";
import {
  saveStaticDataItem,
  queryStaticDataList,
} from "@/project/eztree/baseApi/commonApi";
export default {
  name: "PersonalCenter",
  components: {
    VabQuill: () => import("@/extra/VabQuill"),
  },
  data() {
    return {
      isEdit: false,
      activeName: "privacy",
      form: {
        link_tel: "",
        faq: "",
        agreement: "",
        privacy: "",
      },
      result: {},
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
      rules: {
        params_val: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
      options: {
        theme: "snow",
        bounds: document.body,
        debug: "warn",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ color: [] }, { background: [] }],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              [{ direction: "rtl" }],
              [{ font: [] }],
              ["clean"],
            ],
          },
        },
        placeholder: "内容...",
        readOnly: false,
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "单位",
          prop: "status",
          tooltip: true,
        },
        {
          label: "结果",
          prop: "result",
        },
        {
          label: "创建时间",
          prop: "created_at",
        },
      ];
      return columns;
    },
  },
  created() {},
  mounted() {
    this.initQuery();
  },
  methods: {
    onEdit() {
      this.isEdit = !this.isEdit;
    },
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      querySmsDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },

    onSubmit() {
      let objForm = Object.assign(
        {},
        {
          params_key: this.activeName,
          params_val: this.form[this.activeName],
        }
      );
      console.log(this.activeName, this.form, objForm);
      saveStaticDataItem(objForm).then(({ code, data }) => {
        if (code == "200") {
          this.$baseMessage("操作成功", "success", "vab-hey-message-success");
          this.isEdit = false;
          this.initQuery();
        }
      });
    },
    initQuery() {
      queryStaticDataList().then(({ code, data }) => {
        if (code == "200") {
          this.result = data;
          this.form[this.activeName] = this.result[this.activeName];
        }
      });
    },
    handleClick(tab) {
      console.log(tab.name);
      this.form[this.activeName] = this.result[tab.name];
    },
  },
};
</script>

<style lang="scss" scoped>
$base: ".personal-center";

#{$base}-container {
  padding: 0 !important;
  background: $base-color-background !important;

  #{$base}-user-info {
    padding: $base-padding;
    text-align: center;

    ::v-deep {
      .el-avatar {
        img {
          cursor: pointer;
        }
      }
    }

    &-full-name {
      margin-top: 15px;
      font-size: 24px;
      font-weight: 500;
      color: #262626;
    }

    &-description {
      margin-top: 8px;
    }

    &-follow {
      margin-top: 15px;
    }

    &-list {
      margin-top: 18px;
      line-height: 30px;
      text-align: left;
      list-style: none;

      h5 {
        margin: -20px 0 5px 0;
      }

      ::v-deep {
        .el-tag {
          margin-right: 10px !important;
        }

        .el-tag + .el-tag {
          margin-left: 0;
        }
      }
    }
  }

  #{$base}-item {
    display: flex;

    i {
      font-size: 40px;
    }

    &-content {
      box-sizing: border-box;
      flex: 1;
      margin-left: $base-margin;

      &-second {
        margin-top: 8px;
      }
    }
  }
}
</style>
